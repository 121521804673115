<template>
  <v-dialog
    v-model="modal"
    class="base-modal-filters"
    content-class="base-modal-filters__content elevation-0"
    max-width="798"
  >
    <template #activator="{ on: localOn, attrs: localAttrs }">
      <icon-button v-bind="localAttrs" v-on="localOn">
        <v-icon>
          mdi-filter
        </v-icon>
      </icon-button>
    </template>

    <v-card>
      <v-card-title>
        <div>
          {{ $t('invoice.more_filters') }}
        </div>

        <v-spacer />

        <v-btn icon color="primary" @click="closeModal">
          <v-icon color="primary">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <slot :attrs="attrs" :on="on" />
      </v-card-text>

      <v-card-actions v-if="!media.isMobile">
        <v-btn text color="error" @click="resetAll">{{ $t('button.clear_all_filters') }}</v-btn>

        <v-spacer />

        <v-btn text @click="cancel">{{ $t('button.cancel') }}</v-btn>
        <v-btn text color="primary" @click="applyFilters">{{ $t('button.apply') }}</v-btn>
      </v-card-actions>

      <v-card-actions v-else>
        <v-row no-gutters>
          <v-col class="d-flex justify-center mb-3" cols="12">
            <v-btn text color="error" @click="resetAll">{{ $t('button.clear_all_filters') }}</v-btn>
          </v-col>

          <v-col class="pr-1" cols="6">
            <v-btn block class="elevation-0 primary--text" color="secondary" @click="cancel">{{
              $t('button.cancel')
            }}</v-btn>
          </v-col>

          <v-col class="pl-1" cols="6">
            <v-btn block class="elevation-0" color="primary" @click="applyFilters">{{ $t('button.apply') }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Components
import IconButton from '@/components/IconButton.vue';

// Utils
import { clone } from '@/utils/clone';

export default {
  name: 'BaseModalFilters',

  components: { IconButton },

  inject: ['media'],

  props: {
    value: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      modal: false,
      localValue: {},
    };
  },

  computed: {
    attrs() {
      return { value: this.localValue, enableCache: true, removeLabelSuffix: true, initialData: this.localValue };
    },

    on() {
      return {
        input: value => {
          this.localValue = value;
        },
      };
    },
  },

  watch: {
    value: {
      handler(newValue) {
        this.localValue = clone(newValue);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    closeModal() {
      this.modal = false;
    },

    applyFilters() {
      this.$emit('input', this.localValue);
      this.closeModal();
    },

    cancel() {
      this.localValue = clone(this.value);
      this.closeModal();
    },

    resetAll() {
      this.$emit('reset-filters');
    },
  },
};
</script>

<style lang="scss">
.base-modal-filters {
  max-width: 798px;

  &__content {
    @media (max-width: map.get($--screens, 'sm')) {
      margin: 0 8px 0 8px !important;
    }
  }
}
</style>
